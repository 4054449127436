import request from '@/utils/request'

export function getMessageList(params) {
    return request({
        url: `/notice/listNotice`,
        method: 'GET',
        params,
    })
}

export function checkMessageRed(params) {
    return request({
        url: `/notice/checkUnreadNotice`,
        method: 'GET',
        params,
    })
}


export function setNoticeRead(data) {
    return request({
        url: '/notice/setReadFlag',
        method: 'POST',
        data,
    })
}









