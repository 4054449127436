/**
 * 项目默认配置项
 *
 */
export default {
  navTheme: 'default', // theme for nav menu
  primaryColor: '#00bfbf', // primary color of ant design
  layout: 'sidemenu', // nav menu position: `sidemenu` or `topmenu`
  title: '智慧双碳能源链化管控平台', //项目标题
  multiTab: false
}
