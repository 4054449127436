<template>
  <a-layout class="pages">
    <a-layout-header height="64px">
      <Header />
    </a-layout-header>
    <a-layout class="container">
      <a-layout-sider width="230px" style="background: #fff">
        <Menu/>
      </a-layout-sider>
      <a-layout-content class="padding-20" :class="{ 'need-multi' : settings.multiTab }">
        <MultiTab v-if="settings.multiTab"/>
        <BreadCrumb v-else/>
        <div class="page-cot">
          <Router-view class="wrapper" :class="{'page-border' : settings.multiTab}"/>
        </div>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>

<script>
import defaultSettings from '@/config/defaultSettings'
import Header from '@/components/header/header'
import RouterView from './routeView'
import Menu from '@/components/menu/menu'
import BreadCrumb from '@/components/breadCrumb/breadCrumb'
import MultiTab from '@/components/multiTab'

export default {
  name: 'layout',
  components: {
    Header,
    Menu,
    BreadCrumb,
    MultiTab,
    RouterView
  },
  data () {
    return {
      settings: defaultSettings
    }
  },
  mounted () {

  }
}
</script>

<style lang="scss">
.pages{
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  background-color: #EEF0F5 !important;
  .container{
    overflow: hidden;
    .page-cot{
      height: calc(100% - 45px);
      overflow-y: auto;
      overflow-x: hidden;
      box-sizing: border-box;
      &.page-border {
        border: $border;
        border-top: 0;
        padding: 15px;
      }
    }
  }
  .a-layout-content{
    padding: 15px;
    background-repeat: no-repeat;
    background-position: top right;
    &.need-multi {
      padding: 10px !important;
    }
  }
  .ant-layout-header {
    padding: 0;
    background-color: #282B33;
  }
}
</style>
