import store from '@/store'
import * as loginService from '@/api/login/login'
import layout from '@/components/layout/layout'
import { asyncRouterMap } from '@/config/router.config'
const RouteView = {
  name: 'RouteView',
  render: h => h('router-view')
}

// 前端路由表
const constantRouterComponents = {
  // 基础页面 layout 必须引入
  layout: layout,
  RouteView: RouteView,
  '404': () => import('@/pages/exception/404'),

  // 你需要动态引入的页面组件
  //home
  home: () => import('@/pages/home/home'),
  //project
  projectList: () => import('@/pages/project/projectList'),
  projectDetail: () => import('@/pages/project/projectDetail/projectDetail'),
  sharedFileLibrary: () => import('@/pages/project/sharedFileLibrary'),
  projectTaskTemplate: () => import('@/pages/project/projectTaskTemplate'),
  templateDetail: () => import('@/pages/project/templateDetail/templateDetail'),
  //milestone
  milestone: () => import('@/pages/milestone/milestone'),
  milestoneDetail: () => import('@/pages/milestone/milestoneDetail'),
  //task
  task: () => import('@/pages/task/task'),
  taskDetail: () => import('@/pages/task/taskDetail/taskDetail'),
  //product
  productList: () => import('@/pages/product/productList'),
  productParam: () => import('@/pages/product/productParam'),
  accessoriesAndConsumablesList: () => import('@/pages/product/accessoriesAndConsumablesList'),
  accessoriesAndConsumablesParam: () => import('@/pages/product/accessoriesAndConsumablesParam'),
  //humanResources
  organization: () => import('@/pages/humanResources/organization'),
  jobInfo: () => import('@/pages/humanResources/jobInfo'),
  employeeFile: () => import('@/pages/humanResources/employeeFile'),
  employeeDetail: () => import('@/pages/humanResources/employeeDetail'),
  //customer
  customerFile: () => import('@/pages/customer/customerFile'),
  customerDetail: () => import('@/pages/customer/customerDetail'),
  customerStakeholderProfile: () => import('@/pages/customer/customerStakeholderProfile'),
  customerStakeholderDetail: () => import('@/pages/customer/customerStakeholderDetail'),
  //center
  center: () => import('@/pages/center/center'),
  //system
  user: () => import('@/pages/system/user'),
  role: () => import('@/pages/system/role'),
  news: () => import('@/pages/system/news'),
  settings: () => import('@/pages/system/settings'),
  //system
  message: () => import('@/pages/message/message'),

  Exception404: () => import('@/pages/exception/404'),
}

// 前端未找到页面路由（固定不用改）
const notFoundRouter = {
  path: '*',
  redirect: '/404',
  hidden: true
}

// 根级菜单
const rootRouter = {
  key: '',
  name: 'layout',
  path: '/',
  component: 'layout',
  redirect: '/home',
  meta: {
    title: '首页',
    show: false
  },
  children: []
}

const error ={
  path: '/404',
  name: 'error',
  id: 10026,
  meta: {
    title: '404'
  },
  component: 'Exception404'
}

const notice ={
  path: '/notice',
  name: 'notice',
  id: 10027,
  meta: {
    title: '消息中心',
    show: false
  },
  component: 'notice'
}

/**
 * 动态生成菜单
 * @param token
 * @returns {Promise<Router>}
 */
export const generatorDynamicRouter = data => {
  return new Promise((resolve, reject) => {
    loginService
        .getRolePermission({authType: 1})
        .then(res => {
          // rootRouter.children = asyncRouterMap
          const { data } = res
          const menuNav = []
          rootRouter.redirect = data[0].authCode
          rootRouter.children = mapToTree(data)
          rootRouter.children = rootRouter.children.concat(asyncRouterMap)
          store.commit('SET_ROLES', rootRouter.children)
          // rootRouter.children.push(notice)
          menuNav.push(rootRouter)
          // menuNav.push(error)
          const routers = generator(menuNav)
          // routers.push(notFoundRouter)
          resolve(routers)
        })
        .catch(err => {
          reject(err)
        })
  })
}

const mapToTree = (routerMap, parent) => {
  const newMap = []
  routerMap.map(item => {
    if (item.selected) {
      let components = ''
      if (item.children && item.children.length) {
        components = 'RouteView'
      } else {
        components = item.authCode
      }
      const currMap = {
        name: item.authCode,
        component: components,
        key: item.id,
        path: parent&&parent.path ? parent.path + '/' + item.authCode : '/' + item.authCode,
        meta: {
          title: item.authName,
          show: true
        }
      }
      if (item.children && item.children.length > 0) {
        currMap.redirect = '/' + item.authCode + '/' + item.children[0].authCode
        // Recursion
        currMap.children = mapToTree(item.children, currMap)
      }
      newMap.push(currMap)
    }
  })
  return newMap
}

/**
 * 格式化树形结构数据 生成 vue-router 层级路由表
 *
 * @param routerMap
 * @param parent
 * @returns {*}
 */
export const generator = (routerMap, parents) => {
  return routerMap.map(item => {
    const { title, show, hideChildren, hiddenHeaderContent, target, icon, parent ,keepAlive } = item.meta || {}
    const currentRouter = {
      // 如果路由设置了 path，则作为默认 path，否则 路由地址 动态拼接生成如 /dashboard/workplace
      path: item.path || `${(parents && parents.path) || ''}/${item.key}`,
      // 路由名称，建议唯一
      name: item.name || item.key || '',
      // 该路由对应页面的 组件 :方案1
      // component: constantRouterComponents[item.component || item.key],
      // 该路由对应页面的 组件 :方案2 (动态加载)
      component: constantRouterComponents[item.component || item.key] || (() => import(`@/pages/${item.component}`)),

      // meta: 页面标题, 菜单图标, 页面权限(供指令权限用，可去掉)
      meta: {
        title: title,
        icon: icon || undefined,
        hiddenHeaderContent: hiddenHeaderContent,
        target: target,
        permission: item.name,
        show: show,
        parent: parent,
        keepAlive: keepAlive
      }
    }
    // 是否设置了隐藏菜单
    if (show === false) {
      currentRouter.hidden = true
    }
    // 是否设置了隐藏子菜单
    if (hideChildren) {
      currentRouter.hideChildrenInMenu = true
    }
    // 为了防止出现后端返回结果不规范，处理有可能出现拼接出两个 反斜杠
    if (!currentRouter.path.startsWith('http')) {
      currentRouter.path = currentRouter.path.replace('//', '/')
    }
    // 重定向
    item.redirect && (currentRouter.redirect = item.redirect)
    // 是否有子菜单，并递归处理
    if (item.children && item.children.length > 0) {
      // Recursion
      currentRouter.children = generator(item.children, currentRouter)
    }
    return currentRouter
  })
}

/**
 * 数组转树形结构
 * @param list 源数组
 * @param tree 树
 * @param parentId 父ID
 */
const listToTree = (list, tree, parentId) => {
  list.forEach(item => {
    // 判断是否为父级菜单
    if (item.parentId === parentId) {
      const child = {
        ...item,
        key: item.key || item.name,
        children: []
      }
      // 迭代 list， 找到当前菜单相符合的所有子菜单
      listToTree(list, child.children, item.id)
      // 删掉不存在 children 值的属性
      if (child.children.length <= 0) {
        delete child.children
      }
      // 加入到树中
      tree.push(child)
    }
  })
}
