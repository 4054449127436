<template>
  <div class="header">
    <div class="logo-area">
      <img src="@/assets/image/common/logo.png" alt="">
      <div class="name">数据中心全生命周期管理系统</div>
    </div>
    <div class="menu-area">

    </div>
    <div class="handle-area">
<!--      <span class="date">数字驾驶舱</span>-->
      <router-link class="margin-l60 position-r padding-10" to="/message">
        <img src="@/assets/image/common/notice.png" alt="">
        <span class="red-point" style="top: 24px; right: 5px" v-if="isRed"></span>
      </router-link>
      <img src="@/assets/image/common/header.png" alt="" class="avatar">
      <a-dropdown placement="bottomCenter">
        <span class="a-dropdown-link">
          {{ userInfo.name }}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <a-menu slot="overlay">
          <a-menu-item >
            <div @click="handleLogout">退出登录</div>
          </a-menu-item>
        </a-menu>
      </a-dropdown>
    </div>
  </div>
</template>

<script>
import defaultSettings from '@/config/defaultSettings'
import { mapState } from 'vuex'
import { getNoticeList } from '../../api/notice/notice'
import store from '../../store'
import router from '../../router'
import { checkMessageRed } from '../../api/message/message'
export default {
  name: 'Header',
  data(){
    return{
      title: defaultSettings.title,
      time: '',
      date: '',
      projectInfo: {},
      current: this.$route.name,
      noticeCount: 0,
      isRed: false
    }
  },
  mounted() {
    setInterval(() => {
      this.date = this.$moment().format('YYYY/MM/DD')
      this.time = this.$moment().format('HH:mm:ss')
    },1000)
    this.checkMessageRed()
  },
  methods : {
    goTo (path) {
      if (this.$route.name === path) return
      this.$router.push(path)
      this.$forceUpdate()
    },
    handleLogout () {
      this.$confirm({
        title: '提示',
        content: '确认退出登录?',
        centered: true,
        onOk: () => {
          this.$store.dispatch('Logout').then(() => {
            this.$router.push({ name: 'login' })
          })
        }
      })
    },
    checkMessageRed () {
      checkMessageRed().then(res => {
        this.isRed = res.data
      })
    }
  },
  computed: {
    ...mapState({
      // 动态主路由
      userInfo: state => state.user.info,
      headerMenu: state => state.user.menus,
      menu: state => state.permission.addRouters[0].children,
    }),
    activeMenu () {
      if (localStorage.getItem('active_menu')) {
        return localStorage.getItem('active_menu')
      } else {
        return this.headerMenu[0].id
      }
    }
  },
  watch : {
    $route(to) {
      if (to.query.type) {
        this.current = to.query.type
      }else{
        this.current = to.name
      }
    }
  },
}
</script>

<style lang="scss" scoped>
  .header{
    display: flex;
    align-items: center;
    height: 64px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .12), 0 0 3px 0 rgba(0, 0, 0, .12);
    .logo-area {
      display: flex;
      align-items: center;
      background-color: #282B33;
      height: 100%;
      padding: 0 15px;
      box-sizing: border-box;
      .name {
        margin-left: 20px;
        font-size: 20px;
        color: $white;
      }
    }
    .menu-area {
      display: flex;
      align-items: center;
      flex: 1;
      padding: 0 20px;
      .menu-item {
        display:  flex;
        align-items: center;
        color: #999093;
        cursor: pointer;
        .menu-text {
          margin-left: 10px;
        }
      }
      .el-menu-header {
        &.el-menu--horizontal {
          &>.el-menu-item {
            line-height: 64px;
            height: 64px;
          }
        }
      }
    }
    .handle-area {
      flex: 1;
      display:  flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 30px;
      color: #ADB0B8;
      .notice {
        font-size: 30px;
      }
      .date {
        font-size: 16px;
        margin-left: 10px;
      }
      .time {
        font-size: 20px;
        margin-left: 10px;
      }
      .avatar {
        margin-left: 30px;
        width: 35px;
        height: 35px;
      }
    }
  }
  .a-dropdown-link {
    cursor: pointer;
    margin-left: 10px;
  }
</style>
