

const page = {
  state: {
    currTitle: '',
    breadcrumb: []
  },

  mutations: {
    SET_TITLE: (state, value) => {
      state.currTitle = value
    },
    SET_BREADCRUMB: (state, value) => {
      state.breadcrumb = value
    }
  },

  actions: {
    // 登录

  }
}

export default page
