import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/'
import './assets/css/main.css'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.less'
import moment from 'moment';
import './utils/filter'
import echarts from './utils/echarts'
// import './mock'
import './permission'
import VueAMap from 'vue-amap'
import VueClipboard from 'vue-clipboard2'
import { hasPermission, util } from '@/utils'

import eUpload from '@/components/e-upload/e-upload.vue'


Vue.prototype.$moment = moment
Vue.prototype.$echarts = echarts
Vue.prototype.$util = util

Vue.use(Antd);
Vue.use(VueAMap);
Vue.use(VueClipboard);
Vue.use(hasPermission);

VueAMap.initAMapApiLoader({
  key: '2b24a56b6f11776a376bdeaabd20357d'
});

Vue.config.productionTip = false

Vue.component("eUpload", eUpload)

const app = new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

export default app
