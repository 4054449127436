<template>
  <div>
    <a-upload-dragger
        name="file"
        :multiple="false"
        action="http://118.190.215.184:8383/file/uploadFile"
        :headers="headers"
        :file-list="fileList"
        @change="fileChange"
        :class="{'drag-uploader' : fileList.length}"
        v-if="type === 'file'"
    >
      <p class="ant-upload-drag-icon">
        <a-icon type="cloud-upload" />
      </p>
      <p class="ant-upload-text">
        多个文件请先存入压缩包
      </p>
    </a-upload-dragger>
    <a-upload
        name="file"
        :action="$util.uploadUrl"
        list-type="picture-card"
        :file-list="fileList"
        @preview="handlePreview"
        @change="fileChange"
        :headers="headers"
        v-else
    >
      <div v-if="fileList.length < count">
        <a-icon type="plus" />
        <div class="ant-upload-text">
          上传图片
        </div>
      </div>
    </a-upload>
    <a-modal class="preview-modal" centered :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>


</template>

<script>
import storage from 'store'
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
export default {
  name: 'eUpload',
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    file: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'file'
    },
    count: {
      type: Number,
      default: 9
    }
  },
  data () {
    return {
      headers: { 'token': storage.get('Access-Token') },
      fileList: [],
      previewVisible: false,
      previewImage: ''
    }
  },
  mounted () {
    console.log(this.value)
    if (this.value) {
      this.fileList = [{
        uid: 1001,
        name: this.value,
        url: this.$util.fileUrl + this.value
      }]
    }
  },
  methods: {
    fileChange (res) {
      if (res.file.response) {
        if (res.file.response.code === 10000) {
          this.$emit("change", res.file.response.data)
        } else {
          this.$message.error(res.file.response.message)
        }
      }
      this.fileList = res.fileList
    },
    async handlePreview(file) {
      console.log(file)
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    handleCancel() {
      this.previewVisible = false;
    },
  }
}
</script>

<style lang="scss">
.drag-uploader {
  .ant-upload-drag {
    display: none !important;
  }
}
</style>
