import request from '@/utils/request'
import qs from 'qs'

export function getNoticeList(data) {
    return request({
        url: '/message/list',
        method: 'POST',
        data,
    })
}

export function setNoticeRead(data) {
    return request({
        url: '/notice/setReadFlag',
        method: 'POST',
        data,
    })
}




