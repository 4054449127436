import axios from 'axios'
import store from '@/store'
import storage from 'store'
import { message } from 'ant-design-vue';
import { VueAxios } from './axios'
import { ACCESS_TOKEN } from '@/store/mutation-types'

// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: process.env.NODE_ENV === 'production' ? 'http://118.190.215.184:8383/' : '/api',
  //http://118.190.215.184:8384/
  //http://118.190.215.184:8383/
  timeout: 60000, // 请求超时时间
  withCredentials: true,
})

// 异常拦截处理器
const errorHandler = (error) => {
  if (error.response) {
    const data = error.response.data
    // 从 localstorage 获取 token
    let token = localStorage.getItem('Access-Token')
    if (error.response.data.code === 401) {
      message.error('登录状态已过期！')
      if (token) {
        localStorage.setItem('Access-Token', '')
        this.$route.push('login')
      }
    }
  }
  return Promise.reject(error)
}

// request interceptor
request.interceptors.request.use(config => {
  const token = storage.get(ACCESS_TOKEN)
  // 如果 token 存在
  // 让每个请求携带自定义 token 请根据实际情况自行修改
  if (token) {
    config.headers['token'] = token
  }
  return config
}, errorHandler)

// response interceptor
request.interceptors.response.use((response) => {
  if(response.data.code === 10000 || response.data.code === 200){
    return response.data
  }else{
    if(response.data.message){
      message.error(response.data.message)
    } else {
      message.error('请求失败，请稍后再试！')
    }
    return Promise.reject(response.data)
  }
}, errorHandler)

const installer = {
  vm: {},
  install (Vue) {
    Vue.use(VueAxios, request)
  }
}

export default request

export {
  installer as VueAxios,
  request as axios
}
