<template>
  <a-breadcrumb separator="/">
    <a-breadcrumb-item v-for="(item, index) in lists" :key="item.path">
      <router-link :to="item.path">{{ item.name }}</router-link>
    </a-breadcrumb-item>
  </a-breadcrumb>
</template>

<script>
import app from '../../main'

export default {
  name: 'Breadcrumb',
  data () {
    return {
      lists: []            //定义一个数组 用于接收路由信息
    }
  },
  created () {
    this.getBreadcrumb()
  },
  methods: {
    getBreadcrumb () {
      if (this.$store.state.page.breadcrumb && this.$store.state.page.breadcrumb.length) {
        this.lists = this.$store.state.page.breadcrumb
        app.$store.commit('SET_BREADCRUMB', [])
      } else {
        let matched = this.$route.matched
        matched = matched.filter(item => item.path && item.meta.title).map(item => {
          return {
            name: item.meta.title,
            path: item.path
          }
        })
        this.lists = matched
      }
    }
  },
  watch: {
    $route () {
      this.getBreadcrumb()
    }
  },
}
</script>
<style lang="scss">
  .ant-breadcrumb {
    padding-bottom: 20px !important;
  }
</style>
