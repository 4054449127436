import store from '@/store'
const hasPermission = {
    install (Vue, options) {
        Vue.directive('hasPermission', {
            inserted: (el, binding, vnode)=>{
                filterGlobalPermission(el, binding, vnode);
            }
        });
        Vue.directive('hasAnyPermission', {
            inserted: (el, binding, vnode)=>{
                filterGlobalAnyPermission(el, binding, vnode);
            }
        });
    }
};
/**
 * 全局权限控制
 */
export const filterGlobalPermission = (el, binding, vnode) => {
    let permissionList = store.state.user.authority || [];
    if (!Object.keys(permissionList).length) {
        el.parentNode.removeChild(el);
        return;
    }
    let bool = true
    binding.value.forEach(item => {
        if (!permissionList[item]) {
            bool = false
        }
    })
    if (!bool) {
        el.parentNode.removeChild(el);
    }
}

export const filterGlobalAnyPermission = (el, binding, vnode) => {
    let permissionList = store.state.user.authority || [];
    if (!Object.keys(permissionList).length) {
        el.parentNode.removeChild(el);
        return;
    }
    let bool = false
    binding.value.forEach(item => {
        if (permissionList[item]) {
            bool = true
        }
    })
    if (!bool) {
        el.parentNode.removeChild(el);
    }
}
export default hasPermission;
