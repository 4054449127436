const RouteView = {
    name: 'RouteView',
    render: h => h('router-view')
}

export const asyncRouterMap = [
    {
        path: '/project/projectDetail',
        name: 'projectDetail',
        component: 'projectDetail',
        meta: {
            title: '项目详情',
            show: false,
            parent: {
                title: '项目管理'
            },
            keepAlive: true
        }
    },
    {
        path: '/project/templateDetail',
        name: 'templateDetail',
        component: 'templateDetail',
        meta: {
            title: '项目任务模版详情',
            show: false,
            parent: {
                title: '项目管理'
            }
        }
    },
    {
        path: '/milestone/milestoneDetail',
        name: 'milestoneDetail',
        component: 'milestoneDetail',
        meta: {
            title: '里程碑详情',
            show: false,
            parent: {
                title: '里程碑管理'
            }
        }
    },
    {
        path: '/task/taskDetail',
        name: 'taskDetail',
        component: 'taskDetail',
        meta: {
            title: '任务详情',
            show: false,
            parent: {
                title: '任务管理'
            }
        }
    },
    {
        path: '/humanResources/employeeDetail',
        name: 'employeeDetail',
        component: 'employeeDetail',
        meta: {
            title: '员工详情',
            show: false,
            parent: {
                title: '人力资源管理'
            }
        }
    },
    {
        path: '/customer/customerDetail',
        name: 'customerDetail',
        component: 'customerDetail',
        meta: {
            title: '客户详情',
            show: false,
            parent: {
                title: '客户管理'
            }}
    },
    {
        path: '/customer/customerStakeholderDetail',
        name: 'customerStakeholderDetail',
        component: 'customerStakeholderDetail',
        meta: {
            title: '客户干系人详情',
            show: false,
            parent: {
                title: '客户管理'
            }}
    },
    {
        path: '/message',
        name: 'message',
        component: 'message',
        meta: {
            title: '消息中心',
            show: false,
            icon: 'message'
        }
    },
]

/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [
    {
        path: '/login',
        name: 'login',
        component: () => import('@/pages/login/login'),
        meta: {title: '登录', show: true},
    },
    {
        path: '/404',
        component: () => import('@/pages/exception/404')
    }
]
