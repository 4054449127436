<template>
  <a-row class="tac">
    <a-menu
        :defaultSelectedKeys="[route.name]"
        :open-keys="openKeys"
        mode="inline"
        theme="light"
        @openChange="onOpenChange"
    >
      <template v-for="(item, index) in menu">
        <template v-if="item.children && item.children.length">
          <a-sub-menu :index="item.name" :key="item.name" v-if="item.meta.show">
            <div slot="title" class="menu-item">
              <img :src="require(`@/assets/image/menu/${ item.name }.png`)" alt="">
              <span class="menu-text">{{ item.meta.title }}</span>
            </div>
            <template v-for="(v, i) in item.children">
              <template v-if="v.meta.show">
                <a-menu-item  @click="linkPage(v)" :key="v.name">{{ v.meta.title }}</a-menu-item>
              </template>
            </template>
          </a-sub-menu>
        </template>
        <template v-else>
          <a-menu-item  @click="linkPage(item)" :key="item.name" v-if="item.meta.show">
            <div class="menu-item">
              <img :src="require(`@/assets/image/menu/${ item.name }.png`)" alt="">
              <span class="menu-text">{{ item.meta.title }}</span>
            </div>
          </a-menu-item>
        </template>
      </template>
    </a-menu>
  </a-row>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'Menu',
  data () {
    return {
      openKeys: [],
      route: this.$route
    }
  },
  mounted () {
    if ( this.$route.path ) {
      this.openKeys = [this.$route.path.split('/')[1]]
    }
  },
  methods : {
    linkPage: function (item) {
      this.$router.push({ path: item.path });
    },
    onOpenChange(openKeys) {
      let rootSubmenuKeys = [], secondSubmenuKeys = []
      this.menu.forEach((v) => {
        rootSubmenuKeys.push(v.name)
        if (v.children && v.children.length) {
          v.children.forEach(item => {
            secondSubmenuKeys.push(item.name)
          })
        }
      })
      const latestOpenKey = openKeys.find(key => this.openKeys.indexOf(key) === -1);
      if(openKeys.length > 2 && openKeys[2].indexOf(openKeys[0]) > -1){
        this.openKeys = [openKeys[0], openKeys[2]]
        return
      }
      if(openKeys.length === 2 && openKeys[1].indexOf(openKeys[0]) > -1){
        this.openKeys = openKeys
        return
      }
      if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
        this.openKeys = openKeys
      } else {
        this.openKeys = latestOpenKey ? [latestOpenKey] : []
      }
    },
  },
  computed: {
    ...mapState({
      // 动态主路由
      menu: state => state.permission.addRouters[0].children,
    }),
  },
  watch : {
    $route(to) {
      this.route = to;
    }
  }
}
</script>

<style lang="scss" scoped>
.tac {
  height: 100%;
  /deep/ .ant-menu{
    border-right: 0;
    height: 100%;
    .ant-menu-item {
      padding-left: 20px !important;
      &.ant-menu-item-selected {
        background-color: transparent;
        color: #005BAC;
      }
      &::after {
        border-color: #005BAC;
      }
    }
    .ant-menu-submenu {
      & > .ant-menu {
        width: calc(100% - 75px);
        margin-left: 75px;
        border-left: 3px solid #DFE1E6;
        & > .ant-menu-item {
          margin-left: -20px;
          padding-left: 40px !important;
          padding-right: 5px !important;
          &::after {
            left: 17px;
            right: unset;
            border-width: 3px;
            border-color: #005BAC;
          }
        }
      }
      & > .ant-menu-submenu-title {
        padding-left: 20px !important;
      }
    }
    .menu-item {
      display: flex;
      align-items: center;
      .menu-text {
        margin-left: 10px;
      }
    }
    .ant-menu-item-selected, .ant-menu-submenu-selected, .ant-menu-item:hover, .ant-menu-item-active, .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-submenu-active, .ant-menu-submenu-title:hover {
      color: #005BAC;
    }
  }
}


</style>
