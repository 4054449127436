import request from '@/utils/request'
import qs from 'qs'

export function login(data) {
    return request({
        url: '/user/login',
        method: 'POST',
        data,
    })
}

export function getUserInfo () {
    return request({
        url: '/user/getUserInfo',
        method: 'get'
    })
}

export function getUserPermission () {
    return request({
        url: '/user/getUserPermission',
        method: 'get'
    })
}

export function getRolePermission (data) {
    return request({
        url: '/role/getRolePermission',
        method: 'POST',
        data: data
    })
}

export function getCurrentUserNav (params) {
    return request({
        url: '/user/nav',
        method: 'GET',
        params
    })
}

export function logout () {
    return request({
        url: '/user/logout',
        method: 'post',
    })
}



export function getInfo () {
    return request({
        url: '/user/info',
        method: 'get',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    })
}



