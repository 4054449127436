<template>
  <a-config-provider :locale="zh_CN">
    <div id="app">
      <router-view />
    </div>
  </a-config-provider>
</template>

<script>
import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';
moment.locale('zh-cn');
export default {
  name: 'App',
  components: {

  },
  data() {
    return {
      zh_CN,
    };
  },
}
</script>

<style lang="scss">
//@import "~@/assets/lib/iconfont/iconfont.css";
#app {
  width: 100vw;
  height: 100vh;
  color: #333;
  font-size: 14px;
  font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
}
</style>
